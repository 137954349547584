import React from 'react';
import PropTypes from 'prop-types';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputWrapper from '../InputWrapper';

function Input({
  disabled,
  helperText,
  input: { name, onChange, value },
  meta: { error, touched },
  minRows,
  multiline,
  label,
  required,
}) {
  return (
    <InputWrapper
      disabled={disabled}
      error={error}
      helperText={helperText}
      label={label}
      name={name}
      required={required}
      touched={touched}
    >
      <OutlinedInput
        id={name}
        label={label}
        maxRows={4}
        minRows={minRows}
        multiline={multiline}
        name={name}
        onChange={(e) => onChange(e.target.value)}
        value={value}
      />
    </InputWrapper>
  );
}

Input.defaultProps = {
  disabled: false,
  helperText: '',
  label: '',
  minRows: 1,
  multiline: false,
  required: false,
};

Input.propTypes = {
  disabled: PropTypes.bool,
  helperText: PropTypes.string,
  input: PropTypes.shape({
    name: PropTypes.string,
    onChange: PropTypes.func,
    value: PropTypes.string,
  }).isRequired,
  label: PropTypes.string,
  meta: PropTypes.shape({
    error: PropTypes.string,
    touched: PropTypes.bool,
  }).isRequired,
  minRows: PropTypes.number,
  multiline: PropTypes.bool,
  required: PropTypes.bool,
};

export default Input;
