import PropTypes from 'prop-types';
import React from 'react';
import { Typography } from '@mui/material';

function FormError({ children, ...rest }) {
  return children ? (
    <Typography
      color="error"
      fontSize={12}
      mt="md"
      textAlign="center"
      {...rest}
    >
      {children}
    </Typography>
  ) : null;
}

FormError.propTypes = {
  children: PropTypes.string,
};

FormError.defaultProps = {
  children: null,
};

export default FormError;
