import PropTypes from 'prop-types';
import { composeValidations, email, required } from '@utils/validations';
import { FORM_ERROR } from 'final-form';
import React from 'react';
import get from 'lodash/get';
import { Field, Form as FinalForm } from 'react-final-form';
import Input from '@base/Input';
import { Box } from '@mui/material';
import Password from '@base/Password';
import Button from '@base/Button';
import FormContainer from '@components/FormContainer';

function Login({ setShowPasswordResetPage, signIn }) {
  return (
    <FinalForm
      onSubmit={async (values) => {
        try {
          await signIn(values);
        } catch (e) {
          return {
            [FORM_ERROR]: get(e, 'graphQLErrors[0].message', e.message),
          };
        }
        return null;
      }}
      render={({ handleSubmit, submitError, submitting }) => (
        <FormContainer
          handleSubmit={handleSubmit}
          submitError={submitError}
          submitLabel="Sign In"
          submitting={submitting}
        >
          <Field
            component={Input}
            disabled={submitting}
            label="Email"
            loading={submitting}
            name="username"
            required
            validate={composeValidations(required, email)}
          />
          <Field
            component={Password}
            disabled={submitting}
            label="Password"
            name="password"
            required
            validate={required}
          />
          <Box textAlign="right">
            <Button
              disabled={submitting}
              label="Forgot Password ?"
              onClick={() => setShowPasswordResetPage()}
              variant="text"
            />
          </Box>
        </FormContainer>
      )}
    />
  );
}

Login.propTypes = {
  setShowPasswordResetPage: PropTypes.func.isRequired,
  signIn: PropTypes.func.isRequired,
};
export default Login;
