import React from 'react';
import PropTypes from 'prop-types';
import {
  FormControl,
  FormHelperText,
  FormLabel,
  InputLabel,
} from '@mui/material';

function InputWrapper({
  children,
  disabled,
  error,
  helperText,
  label,
  labelType,
  name,
  required,
  touched,
}) {
  const displayError = !!error && touched;
  const LabelComponent = labelType === 'formLabel' ? FormLabel : InputLabel;
  return (
    <FormControl
      disabled={disabled}
      error={displayError}
      fullWidth
      label={label}
      margin="none"
      required={required}
      size="small"
      variant="outlined"
    >
      <LabelComponent htmlFor={name} id={name}>
        {label}
      </LabelComponent>
      {children}
      <FormHelperText error={displayError}>
        {displayError ? error : helperText}
      </FormHelperText>
    </FormControl>
  );
}

InputWrapper.defaultProps = {
  children: null,
  disabled: false,
  error: '',
  helperText: '',
  label: '',
  labelType: 'inputLabel',
  required: false,
  touched: false,
};

InputWrapper.propTypes = {
  children: PropTypes.node,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  helperText: PropTypes.string,
  label: PropTypes.string,
  labelType: PropTypes.oneOf(['formLabel', 'inputLabel']),
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
  touched: PropTypes.bool,
};

export default InputWrapper;
