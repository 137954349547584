import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Button from '@base/Button';
import { Container, useTheme } from '@mui/material';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';
import Alert from '@base/Alert';
import LoginIcon from '@mui/icons-material/Login';
import { Auth } from 'aws-amplify';
import EmailForm from './EmailForm';
import PasswordForm from './PasswordForm';

function PasswordReset({ passwordReset, setBackToLogin }) {
  const [activeStep, setActiveStep] = useState(0);
  const [em, setEm] = useState(null);
  const { palette } = useTheme();

  return (
    <Container fixed>
      <Stepper activeStep={activeStep} orientation="vertical">
        <Step key="step1">
          <StepLabel>Step 1</StepLabel>
          <StepContent>
            <EmailForm
              onComplete={async (e) => {
                await Auth.forgotPassword(e);
                setEm(e);
                setActiveStep(1);
              }}
            />
          </StepContent>
        </Step>

        <Step key="step2">
          <StepLabel>Step 2</StepLabel>
          <StepContent>
            <PasswordForm
              onComplete={async (code, newPassword) => {
                await passwordReset(em, code, newPassword);
                setActiveStep(2);
              }}
              onFailure={() => {
                setEm(null);
                setActiveStep(2);
              }}
            />
          </StepContent>
        </Step>

        <Step key="step3">
          <StepLabel>Finish</StepLabel>
          <StepContent>
            <Alert
              text="You will be redirected to login page in 10 seconds"
              title="Password reset complete"
              type="success"
              visible={em}
            />
            <Alert
              text=" You will be redirected to login page in 10 seconds"
              title="Time Out"
              type="error"
              visible={!em}
            />
            <Box
              alignItems="center"
              display="flex"
              justifyContent="space-between"
              my={2}
            >
              <Button
                icon={<LoginIcon />}
                label="Back to Login"
                onClick={() => setBackToLogin()}
              />
              <CountdownCircleTimer
                key="timer"
                colors={[
                  palette.primary.dark,
                  palette.primary.main,
                  palette.primary.light,
                ]}
                colorsTime={[10, 5, 0]}
                duration={10}
                initialRemainingTime={10}
                isPlaying
                onComplete={() => setBackToLogin()}
                size={40}
                strokeLinecap="rounded"
                strokeWidth={5}
              >
                {({ remainingTime }) => remainingTime}
              </CountdownCircleTimer>
            </Box>
          </StepContent>
        </Step>
      </Stepper>
      {activeStep !== 2 && (
        <Button
          label="Back to Login"
          onClick={() => setBackToLogin()}
          variant="outlined"
        />
      )}
    </Container>
  );
}

PasswordReset.propTypes = {
  passwordReset: PropTypes.func.isRequired,
  setBackToLogin: PropTypes.func.isRequired,
};
export default PasswordReset;
