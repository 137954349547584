import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Container from '@mui/material/Container';
import NameForm from './NameForm';
import PasswordForm from './PasswordForm';

function SignUp({ completeNewPasswordChallenge }) {
  const [activeStep, setActiveStep] = useState(0);
  const [name, setName] = useState(null);

  return (
    <Container fixed>
      <Stepper activeStep={activeStep} orientation="vertical">
        <Step key="step1">
          <StepLabel>Details</StepLabel>
          <StepContent>
            <NameForm
              onComplete={async ({ familyName, givenName }) => {
                setName({ familyName, givenName });
                setActiveStep(1);
              }}
            />
          </StepContent>
        </Step>

        <Step key="step2">
          <StepLabel>Change Password</StepLabel>
          <StepContent>
            <PasswordForm
              onComplete={async (newPassword) => {
                await completeNewPasswordChallenge({
                  familyName: name.familyName,
                  givenName: name.givenName,
                  password: newPassword,
                });
                setActiveStep(2);
              }}
            />
          </StepContent>
        </Step>
      </Stepper>
    </Container>
  );
}

SignUp.propTypes = {
  completeNewPasswordChallenge: PropTypes.func.isRequired,
};
export default SignUp;
