import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Button from '@base/Button';
import { Field, Form as FinalForm } from 'react-final-form';
import { FORM_ERROR } from 'final-form';
import { get } from 'lodash';
import Password from '@base/Password';
import {
  composeValidations,
  match,
  required,
} from '@src/utilities/validations';
import FormContainer from '@components/FormContainer';

function PasswordChange({ changePassword, signOut }) {
  return (
    <FinalForm
      onSubmit={async ({ oldPassword, newPassword }) => {
        try {
          await changePassword(oldPassword, newPassword);
        } catch (e) {
          return {
            [FORM_ERROR]: get(e, 'graphQLErrors[0].message', e.message),
          };
        }
        return null;
      }}
      render={({
        handleSubmit,
        submitError,
        submitting,
        values: { newPassword },
      }) => (
        <FormContainer
          handleSubmit={handleSubmit}
          submitError={submitError}
          submitLabel="Change Password"
          submitting={submitting}
        >
          <Box textAlign="right">
            <Button
              disabled={submitting}
              label="Sign Out"
              onClick={() => signOut()}
              variant="text"
            />
          </Box>
          <Field
            component={Password}
            disabled={submitting}
            label="Current Password"
            name="oldPassword"
            required
            validate={required}
          />
          <Field
            component={Password}
            disabled={submitting}
            label="New Password"
            name="newPassword"
            required
            validate={required}
          />
          <Field
            component={Password}
            disabled={submitting}
            label="Verify Password"
            name="verifiedPassword"
            required
            validate={composeValidations(
              match('Passwords', newPassword),
              required
            )}
          />
        </FormContainer>
      )}
    />
  );
}

PasswordChange.propTypes = {
  changePassword: PropTypes.func.isRequired,
  signOut: PropTypes.func.isRequired,
};
export default PasswordChange;
