import PropTypes from 'prop-types';
import React from 'react';
import { Helmet } from 'react-helmet';
import App from './App';
import config from '../config.json';

function Layout({ children }) {
  return (
    <App>
      <Helmet>
        <link
          href={`/Images/${config.favicon}`}
          rel="icon"
          sizes="16x16"
          type="image/png"
        />
      </Helmet>
      {children}
    </App>
  );
}

Layout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]).isRequired,
  location: PropTypes.shape({ pathname: PropTypes.string }).isRequired,
};

export default Layout;
