import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Button from '@base/Button';
import { Field, Form as FinalForm } from 'react-final-form';
import { FORM_ERROR } from 'final-form';
import Input from '@base/Input';
import {
  composeValidations,
  email,
  required,
} from '@src/utilities/validations';
import FormError from '@components/FormError';

function EmailForm({ onComplete }) {
  return (
    <FinalForm
      onSubmit={async (values) => {
        const val = values.email;
        try {
          await onComplete(val);
        } catch (e) {
          return {
            [FORM_ERROR]: e.message,
          };
        }
      }}
      render={({ handleSubmit, submitting, submitError }) => (
        <Box component="form" onSubmit={handleSubmit}>
          <Field
            component={Input}
            disabled={submitting}
            label="Email"
            name="email"
            required
            validate={composeValidations(required, email)}
          />
          <Button
            disabled={submitting}
            label="Send Code"
            loading={submitting}
            type="submit"
            variant="contained"
          />
          <FormError>{submitError}</FormError>
        </Box>
      )}
    />
  );
}

EmailForm.propTypes = {
  onComplete: PropTypes.func.isRequired,
};
export default EmailForm;
