import React from 'react';
import PropTypes from 'prop-types';
import { Badge, Button as MUIButton } from '@mui/material';
import Spinner from '@base/Spinner';
import { Link } from 'gatsby';

const textProps = {
  ':hover': {
    backgroundColor: 'inherit',
    textDecoration: 'underline',
  },
  backgroundColor: 'inherit',
  fontSize: 'inherit',
  p: 0,
  textTransform: 'none',
};

function Button({
  badge,
  color,
  disabled,
  fullWidth,
  icon,
  label,
  link,
  loading,
  onClick,
  size,
  type,
  variant,
}) {
  const button = (
    <MUIButton
      color={color}
      disabled={disabled}
      endIcon={
        loading ? (
          <Spinner color="inherit" text="" />
        ) : (
          <Badge
            badgeContent={badge}
            color="secondary"
            invisible={!badge}
            max={10000}
            variant={typeof badge === 'boolean' ? 'dot' : 'standard'}
          >
            {icon}
          </Badge>
        )
      }
      fullWidth={fullWidth}
      onClick={onClick}
      size={size}
      sx={variant === 'text' ? textProps : {}}
      type={type}
      variant={variant}
    >
      {label}
    </MUIButton>
  );
  if (link) {
    return (
      <Link disabled={disabled} to={link}>
        {button}
      </Link>
    );
  }
  return button;
}

Button.defaultProps = {
  badge: 0,
  color: 'primary',
  disabled: false,
  fullWidth: false,
  icon: null,
  label: '',
  link: '',
  loading: false,
  onClick: () => null,
  size: 'medium',
  type: 'button',
  variant: 'contained',
};

Button.propTypes = {
  badge: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  color: PropTypes.oneOf(['error', 'info', 'primary', 'secondary', 'success']),
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  icon: PropTypes.node,
  label: PropTypes.string,
  link: PropTypes.string,
  loading: PropTypes.bool,
  onClick: PropTypes.func,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  type: PropTypes.oneOf(['button', 'submit']),
  variant: PropTypes.oneOf(['contained', 'outlined', 'text']),
};

export default Button;
