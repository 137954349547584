import PropTypes from 'prop-types';
import { FORM_ERROR } from 'final-form';
import React from 'react';
import get from 'lodash/get';
import { Form as FinalForm } from 'react-final-form';
import FormContainer from '@components/FormContainer';

function FederatedLogin({ federatedSignIn }) {
  return (
    <FinalForm
      onSubmit={async () => {
        try {
          await federatedSignIn();
        } catch (e) {
          return {
            [FORM_ERROR]: get(e, 'graphQLErrors[0].message', e.message),
          };
        }
        return null;
      }}
      render={({ handleSubmit, submitError, submitting }) => (
        <FormContainer
          handleSubmit={handleSubmit}
          submitError={submitError}
          submitLabel="Federated Sign In"
          submitting={submitting}
        />
      )}
    />
  );
}

FederatedLogin.propTypes = {
  federatedSignIn: PropTypes.func.isRequired,
};
export default FederatedLogin;
