import { createTheme } from '@mui/material';
import {
  blue,
  blueGrey,
  grey,
  cyan,
  green,
  orange,
  purple,
  indigo,
  teal,
  pink,
} from '@mui/material/colors';

const defaultTheme = createTheme({});

const getColors = (color) => ({
  contrastText: color[50],
  dark: color[900],
  light: color[300],
  main: color[600],
});
const chartColors = [blue, green, orange, cyan, purple, indigo, teal, pink];

export default createTheme({
  components: {
    MuiPaper: {
      defaultProps: {
        variant: 'outlined',
      },
    },
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          code: 'pre',
        },
      },
    },
  },
  palette: {
    background: {
      default: grey[200],
    },
    chartColors: [
      ...chartColors.map((c) => getColors(c).main),
      ...chartColors.map((c) => getColors(c).dark),
    ],
    grey: {
      contrastText: grey[100],
      dark: grey[900],
      light: grey[300],
      main: grey[700],
    },
    info: getColors(blueGrey),
    primary: getColors(blue),
    secondary: getColors(cyan),
  },
  typography: {
    code: {
      fontSize: defaultTheme.typography.caption.fontSize,
    },
    fontFamily: 'Poppins',
  },
});
