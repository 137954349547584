export const COGNITO_USER_ATTRIBUTES = {
  ACTIVE_GROUP: 'custom:active_group',
  EMAIL: 'email',
  EMAIL_ALIAS: 'cognito:email_alias',
  EMAIL_VERIFIED: 'email_verified',
  FAMILY_NAME: 'family_name',
  GIVEN_NAME: 'given_name',
  GROUPS: 'cognito:groups',
  MFA_ENABLED: 'cognito:mfa_enabled',
  PHONE_NUMBER_VERIFIED: 'phone_number_verified',
  SUB: 'sub',
  USER_STATUS: 'cognito:user_status',
};

export const HEADERS = {
  ACTIVE_GROUP: 'x-spr-active-group',
  API_KEY: 'x-api-key',
  AUTHORIZATION: 'Authorization',
  CACHE_CONTROL: 'Cache-Control',
  CLIENT_ID: 'x-spr-client-id',
  CONTENT_LENGTH: 'Content-Length',
  CONTENT_TYPE: 'Content-Type',
  EXPECT_CT: 'expect-ct',
  EXTERNAL_USER_ID: 'externalUserId',
  FINEOS_USER_ID: 'userid',
  HC_PARENT_ID: 'x-spr-hc-parent-id',
  HC_SPAN_PARENT_ID: 'x-spr-hc-span-parent-id',
  JWT: 'x-spr-jwt',
  PERMISSIONS_POLICY: 'permisssions-policy',
  REFERRER_POLICY: 'Referrer-Policy',
  STRICT_TRANSPORT_SECURITY: 'Strict-Transport-Security',
  TRACE_ID: 'x-spr-hc-trace-id',
  USER_ID: 'x-spr-user-id',
  X_CONTENT_TYPE_OPTIONS: 'X-Content-Type-Options',
  X_FRAME_OPTIONS: 'X-Frame-Options',
  X_XSS_PROTECTION: 'X-Xss-Protection',
};
export const HEADER_VALUES = {
  CACHE_CONTROL_VALUE: 'no-store',
  EXPECT_CT_VALUE: 'max-age=0',
  PERMISSIONS_POLICY_VALUE:
    'accelerometer=(),camera=(),geolocation=(),gyroscope=(),magnetometer=(),microphone=(),payment=(),USB=()',
  REFERRER_POLICY_VALUE: 'strict-origin',
  STRICT_TRANSPORT_SECURITY_VALUE:
    'max-age=31536000; includeSubDomains; preload',
  X_CONTENT_TYPE_OPTIONS_VALUE: 'nosniff',
  X_FRAME_OPTIONS_VALUE: 'SAMEORIGIN',
  X_XSS_PROTECTION_VALUE: '1; mode=block',
};

export const REGEXES = {
  UUID: '[a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12}',
  VERSION_NUMBER: '[0-9.]+',
};

export const UUID_PREFIXES = {
  USER: 'spr:user::',
};

export const STATUS_CODES = {
  BAD_REQUEST: 400,
};
