import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { IconButton, InputAdornment, OutlinedInput } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import InputWrapper from '../InputWrapper';

function Password({
  disabled,
  helperText,
  input: { name, onChange },
  meta: { error, touched },
  label,
  required,
}) {
  const [showPassword, setShowPassword] = useState(false);
  return (
    <InputWrapper
      disabled={disabled}
      error={error}
      helperText={helperText}
      label={label}
      name={name}
      required={required}
      touched={touched}
    >
      <OutlinedInput
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              edge="end"
              onMouseDown={(event) => {
                event.preventDefault();
              }}
              onPointerEnter={() => setShowPassword(true)}
              onPointerLeave={() => setShowPassword(false)}
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        }
        id={name}
        label={label}
        name={name}
        onChange={(e) => onChange(e.target.value)}
        type={showPassword && !disabled ? 'text' : 'password'}
      />
    </InputWrapper>
  );
}

Password.defaultProps = {
  disabled: false,
  helperText: '',
  label: '',
  required: false,
};

Password.propTypes = {
  disabled: PropTypes.bool,
  helperText: PropTypes.string,
  input: PropTypes.shape({
    name: PropTypes.string,
    onChange: PropTypes.func,
    value: PropTypes.string,
  }).isRequired,
  label: PropTypes.string,
  meta: PropTypes.shape({
    error: PropTypes.string,
    touched: PropTypes.bool,
  }).isRequired,
  required: PropTypes.bool,
};

export default Password;
