import React from 'react';
import PropTypes from 'prop-types';
import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  ApolloProvider,
  createHttpLink,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { createAuthLink } from 'aws-appsync-auth-link';
import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link';
import config from '@config';
import { HEADERS, HEADER_VALUES } from '@src/utilities/helper-constants';
import { Auth } from 'aws-amplify';
import { v4 as uuidv4 } from 'uuid';
import { useIdleTimer } from 'react-idle-timer';
import { get } from 'lodash';

function SignedIn({ jwt, children, signOut }) {
  useIdleTimer({
    debounce: 1000,
    onIdle: async () => signOut(),
    timeout: 1000 * 60 * 15,
  });

  const { auth, url, region } = get(config, 'apollo');
  const link = ApolloLink.from([
    createAuthLink({
      auth: { credentials: Auth.currentCredentials, type: auth.type },
      region,
      url,
    }),
    createSubscriptionHandshakeLink(
      { auth, region, url },
      ApolloLink.from([
        setContext((request, previousContext) => ({
          headers: {
            ...previousContext.headers,
            [HEADERS.ACTIVE_GROUP]: 'Spraoi',
            // [HEADERS.JWT]: jwt,
            [HEADERS.TRACE_ID]: uuidv4(),
            [HEADERS.CACHE_CONTROL]: [HEADER_VALUES.CACHE_CONTROL_VALUE],
            [HEADERS.EXPECT_CT]: [HEADER_VALUES.EXPECT_CT_VALUE],
            [HEADERS.PERMISSIONS_POLICY]: [
              HEADER_VALUES.PERMISSIONS_POLICY_VALUE,
            ],
            [HEADERS.REFERRER_POLICY]: [HEADER_VALUES.REFERRER_POLICY_VALUE],
            [HEADERS.STRICT_TRANSPORT_SECURITY]: [
              HEADER_VALUES.STRICT_TRANSPORT_SECURITY_VALUE,
            ],
            [HEADERS.X_CONTENT_TYPE_OPTIONS]: [
              HEADER_VALUES.X_CONTENT_TYPE_OPTIONS_VALUE,
            ],
            [HEADERS.X_FRAME_OPTIONS]: [HEADER_VALUES.X_FRAME_OPTIONS_VALUE],
            [HEADERS.X_XSS_PROTECTION]: [HEADER_VALUES.X_XSS_PROTECTION_VALUE],
          },
        })),
        createHttpLink({ uri: url }),
      ])
    ),
  ]);
  const client = new ApolloClient({
    cache: new InMemoryCache(),
    link,
  });
  return <ApolloProvider client={client}>{children}</ApolloProvider>;
}

SignedIn.propTypes = {
  children: PropTypes.node.isRequired,
  jwt: PropTypes.string.isRequired,
  signOut: PropTypes.func.isRequired,
};

export default SignedIn;
