import React from 'react';
import PropTypes from 'prop-types';
import { Alert as MUIAlert, AlertTitle } from '@mui/material';

function Alert({ text, title, type, visible }) {
  return (
    visible && (
      <MUIAlert severity={type}>
        {title && <AlertTitle>{title}</AlertTitle>}
        {text}
      </MUIAlert>
    )
  );
}

Alert.defaultProps = {
  text: '',
  title: '',
  type: 'info',
  visible: true,
};

Alert.propTypes = {
  text: PropTypes.string,
  title: PropTypes.string,
  type: PropTypes.oneOf(['error', 'info', 'success', 'warning']),
  visible: PropTypes.bool,
};

export default Alert;
