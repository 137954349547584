import React from 'react';
import PropTypes from 'prop-types';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import config from '@config';
import { Divider, LinearProgress, Stack } from '@mui/material';
import { get } from 'lodash';
import Logo from '../Logo';

function Copyright() {
  return (
    <Box width="100%">
      <Typography align="center" color="text.secondary" variant="body2">
        Copyright © FINEOS {new Date().getFullYear()}.{' '}
        {config.version ? `v${get(config, 'version', '')}` : ''}
      </Typography>
    </Box>
  );
}

function Background({ children, loading, title }) {
  return (
    <Grid component="main" container sx={{ height: '100vh' }}>
      <Grid
        item
        md={7}
        sm={4}
        sx={{
          backgroundColor: (t) =>
            t.palette.mode === 'light'
              ? t.palette.grey[50]
              : t.palette.grey[900],
          backgroundImage: `url(/Images/${get(config, 'background')})`,
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
        }}
        xs={false}
      />
      <Grid component={Paper} elevation={24} item md={5} sm={8} square xs={12}>
        {loading && <LinearProgress />}
        <Box
          sx={{
            alignItems: 'stretch',
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            justifyContent: 'space-between',
            p: 5,
          }}
        >
          <Stack spacing={3}>
            <Stack
              alignItems="flex-end"
              direction="row"
              justifyContent="space-between"
            >
              <Logo color="primary.dark" width={100} />
              <Typography
                color="primary.dark"
                component="h1"
                fontWeight={700}
                variant="h4"
              >
                Barrel
              </Typography>
            </Stack>
            <Divider sx={{ my: 2 }} />
            <Typography component="h2" variant="h5">
              {title}
            </Typography>
            {children}
          </Stack>
          <Copyright />
        </Box>
      </Grid>
    </Grid>
  );
}

Background.defaultProps = {
  children: null,
  loading: false,
  title: '',
};

Background.propTypes = {
  children: PropTypes.node,
  loading: PropTypes.bool,
  title: PropTypes.string,
};

export default Background;
