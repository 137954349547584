import React from 'react';
import PropTypes from 'prop-types';
import Button from '@base/Button';
import Alert from '@base/Alert';
import { Stack } from '@mui/material';

function FormContainer({
  children,
  disabled,
  onClick,
  handleSubmit,
  submitError,
  submitLabel,
  submitting,
}) {
  return (
    <form onSubmit={handleSubmit}>
      <Stack spacing={2}>
        {children}
        <Alert
          text={submitError}
          title="Error"
          type="error"
          visible={!!submitError}
        />
        <Button
          disabled={disabled}
          fullWidth
          label={submitLabel}
          loading={submitting}
          onClick={onClick || handleSubmit}
          type="submit"
        />
      </Stack>
    </form>
  );
}

FormContainer.propTypes = {
  children: PropTypes.node,
  disabled: PropTypes.bool,
  handleSubmit: PropTypes.func.isRequired,
  onClick: PropTypes.func,
  submitError: PropTypes.string,
  submitLabel: PropTypes.string.isRequired,
  submitting: PropTypes.bool.isRequired,
};

FormContainer.defaultProps = {
  children: null,
  disabled: false,
  onClick: () => null,
  submitError: null,
};

export default FormContainer;
