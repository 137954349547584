import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Button from '@base/Button';
import { Field, Form as FinalForm } from 'react-final-form';
import { FORM_ERROR } from 'final-form';
import Input from '@base/Input';
import { required } from '@src/utilities/validations';
import FormError from '@components/FormError';
import { NavigateNext } from '@mui/icons-material';
import { Grid } from '@mui/material';

function NameForm({ onComplete }) {
  return (
    <FinalForm
      onSubmit={async ({ familyName, givenName }) => {
        try {
          await onComplete({ familyName, givenName });
        } catch (e) {
          return {
            [FORM_ERROR]: e.message,
          };
        }
      }}
      render={({ handleSubmit, submitting, submitError }) => (
        <Box component="form" onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item sm={6} xs={12}>
              <Field
                component={Input}
                disabled={submitting}
                label="First Name"
                name="givenName"
                required
                validate={required}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <Field
                component={Input}
                disabled={submitting}
                label="Last Name"
                name="familyName"
                required
                validate={required}
              />
            </Grid>
          </Grid>

          <Button
            disabled={submitting}
            icon={<NavigateNext />}
            label="Next"
            loading={submitting}
            type="submit"
            variant="contained"
          />
          <FormError>{submitError}</FormError>
        </Box>
      )}
    />
  );
}

NameForm.propTypes = {
  onComplete: PropTypes.func.isRequired,
};
export default NameForm;
