import moment from 'moment';
import isEmail from 'validator/lib/isEmail';
import { minPasswordLength } from '@config';

const inputReferenceKeyRegex = /^\$\$.+/;

const uuidRegexString =
  '[a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12}';

export const endWithLetter = (value) => {
  if (!/[a-z]$/i.test(value)) return 'Must end with a letter';
};

export const inputReferenceKey = (keys) => (value) => {
  if (inputReferenceKeyRegex.test(value) && !keys.includes(value)) {
    return 'Invalid input reference key';
  }
};

export const email = (value) => {
  if (value && !isEmail(value)) {
    return 'Invalid email';
  }
  return null;
};

export const integer = (value) => {
  if (value && !/^[-+]?\d+$/.test(value)) return 'Invalid integer';
  return null;
};

export const float = (precision) =>
  function validator(value) {
    const maxPrecision = precision || 2;
    if (
      value &&
      !new RegExp(`^[-+]?[0-9]+.[0-9]{1,${maxPrecision}}$`).test(value)
    ) {
      return 'Invalid float';
    }
    return null;
  };

export const json = (value) => {
  if (!value) return null;

  try {
    JSON.parse(value);
  } catch (e) {
    return 'Invalid JSON';
  }
  return null;
};

export const checkFileExtension = (allowExtensions) =>
  function validator(value) {
    if (!allowExtensions.includes(/(?:\.([^.]+))?$/.exec(value)[1])) {
      return `Allowed extensions: ${allowExtensions.join(', ')}`;
    }
    return null;
  };

export const date = (format) =>
  function validator(value) {
    if (value && !moment(value, format, true).isValid()) return 'Invalid date';
    return null;
  };

export const match = (name, match) =>
  function validator(value) {
    if (value !== match) return 'Passwords must match';
    return null;
  };

export const minLength = (length) =>
  function validator(value) {
    if (value && value.length < length) {
      return `Must be at least ${length} characters`;
    }
    return null;
  };

export const lettersNumbersDashesAndUnderscores = (value) => {
  if (!/^[a-z0-9-_]*$/i.test(value)) {
    return 'Must contain only letters, numbers, dashes and underscores';
  }
  return null;
};

export const required = (value) => {
  if (value === undefined || value === '' || value === null) return 'Required';
  return null;
};

export const positive = (value) => {
  if (value && !/^\d+$/i.test(value)) {
    return 'Must be a positive number';
  }
  return null;
};

export const uniq = (values) => (value) => {
  if (values.includes(value)) {
    return 'Must be unique';
  }
};

export const uuid = (type) => (value) => {
  if (
    !inputReferenceKeyRegex.test(value) &&
    !new RegExp(`spr:bz:${type}::${uuidRegexString}`).test(value)
  ) {
    return `Invalid ${type} id`;
  }
};

export const startWithLetter = (value) => {
  if (!/^[a-z]/i.test(value)) return 'Must start with a letter';
};

export const composeValidations =
  (...validators) =>
  (value, allValues, fieldState) =>
    validators.reduce(
      (error, validator) => error || validator(value, allValues, fieldState),
      undefined
    );

export const validateGreaterThan = (comparingValue, unit) =>
  function innerfunction(value) {
    if (value < comparingValue) {
      return `Should be at least ${comparingValue} ${unit}`;
    }
    return null;
  };

export const different = (comparingValue) =>
  function validator(value) {
    if (comparingValue === value) {
      return `Must be different`;
    }
    return null;
  };

export const checkIfStrongPass = (value) => ({
  hasDigit: value && /\d/.test(value),
  hasLowercase: value && /[a-z]/.test(value),
  hasSpecialCharacter: value && /[^\w\d]/.test(value),
  hasUppercase: value && /[A-Z]/.test(value),
  isLengthValid: value && value.length >= minPasswordLength,
});

export const validateInput = (value) => {
  if (!value) return null;
  if (/[=+\-@]/.test(String(value)[0])) {
    return `Must not start with '=', '+', '-', or '@'`;
  }
};
