import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Button from '@base/Button';
import { Field, Form as FinalForm } from 'react-final-form';
import { FORM_ERROR } from 'final-form';
import Input from '@base/Input';
import {
  composeValidations,
  match,
  minLength,
  required,
} from '@src/utilities/validations';
import { useTheme } from '@mui/material';
import config from '@config';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';
import Alert from '@base/Alert';
import Password from '@base/Password';
import { get } from 'lodash';

function PasswordForm({ onComplete, onFailure }) {
  const { palette } = useTheme();

  return (
    <FinalForm
      onSubmit={async ({ code, newPassword }) => {
        try {
          await onComplete(code, newPassword);
        } catch (e) {
          const errorMessage = e.message;
          return {
            [FORM_ERROR]: errorMessage,
          };
        }
      }}
      render={({ handleSubmit, submitError, submitting, values }) => (
        <Box component="form" onSubmit={handleSubmit}>
          <Alert
            text={`If the email you entered
                    was correct, you will receive an email with a verification
                    code. Enter it below to verify your identity.`}
          />
          <Field
            component={Input}
            disabled={submitting}
            label="Verification Code"
            name="code"
            required
            validate={composeValidations(required)}
          />
          <Field
            component={Password}
            disabled={submitting}
            label="New Password"
            name="newPassword"
            required
            validate={composeValidations(
              minLength(get(config, 'minPasswordLength', 0)),
              required
            )}
          />
          <Field
            component={Password}
            disabled={submitting}
            label="Verify Password"
            name="verifiedPassword"
            required
            validate={composeValidations(
              match('Passwords', values.newPassword),
              required
            )}
          />

          <Alert
            text={submitError}
            title="Error"
            type="error"
            visible={!!submitError}
          />
          <Box
            alignItems="center"
            display="flex"
            justifyContent="space-between"
            my={2}
          >
            <Button
              disabled={submitting}
              label="Reset Password"
              loading={submitting}
              type="submit"
            />
            <CountdownCircleTimer
              key="timer"
              colors={[
                palette.success.main,
                palette.warning.main,
                palette.error.main,
              ]}
              colorsTime={[120, 60, 30]}
              duration={120}
              initialRemainingTime={120}
              isPlaying
              onComplete={() => onFailure()}
              size={40}
              strokeLinecap="rounded"
              strokeWidth={5}
            >
              {({ remainingTime }) => remainingTime}
            </CountdownCircleTimer>
          </Box>
        </Box>
      )}
    />
  );
}

PasswordForm.propTypes = {
  onComplete: PropTypes.func.isRequired,
  onFailure: PropTypes.func.isRequired,
};
export default PasswordForm;
