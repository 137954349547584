import React from 'react';
import config from '@config';
import { Box } from '@mui/material';
import { get } from 'lodash';
import fineosmono from '../../images/icons/fineos-mono.svg';

function Logo(props) {
  const SvgComponent = fineosmono;
  return get(config, 'logo', '') ? (
    <Box {...props}>
      <SvgComponent />
    </Box>
  ) : null;
}

export default Logo;
