import React from 'react';
import PropTypes from 'prop-types';
import { CircularProgress } from '@mui/material';

function Spinner({ color }) {
  return <CircularProgress color={color} size={24} />;
}

Spinner.defaultProps = {
  color: 'info',
};

Spinner.propTypes = {
  color: PropTypes.string,
};

export default Spinner;
