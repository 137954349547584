import PropTypes from 'prop-types';
import React from 'react';
import { FORM_ERROR } from 'final-form';
import { Field, Form as FinalForm } from 'react-final-form';
import {
  composeValidations,
  match,
  minLength,
  required,
} from '@utils/validations';
import { get } from 'lodash';
import config from '@config';
import Password from '@base/Password';
import Button from '@base/Button';
import DoneIcon from '@mui/icons-material/Done';
import Alert from '@base/Alert';
import FormContainer from '@components/FormContainer';

function PasswordForm({ onComplete }) {
  return (
    <FinalForm
      initialValues={{}}
      onSubmit={async ({ newPassword }) => {
        try {
          await onComplete(newPassword);
        } catch (e) {
          return {
            [FORM_ERROR]: get(e, 'graphQLErrors[0].message', e.message),
          };
        }
      }}
      render={({
        handleSubmit,
        submitError,
        submitting,
        values: { newPassword },
      }) => (
        <FormContainer
          handleSubmit={handleSubmit}
          submitError={submitError}
          submitLabel="Complete Sign Up"
          submitting={submitting}
        >
          <Field
            component={Password}
            disabled={submitting}
            label="New Password"
            name="newPassword"
            required
            validate={composeValidations(
              minLength(get(config, 'minPasswordLength', 0)),
              required
            )}
          />
          <Field
            component={Password}
            disabled={submitting}
            label="Verify Password"
            name="verifiedPassword"
            required
            validate={composeValidations(
              match('Passwords', newPassword),
              required
            )}
          />
          <Alert
            text={submitError}
            title="Error"
            type="error"
            visible={!!submitError}
          />
          <Button
            disabled={submitting}
            icon={<DoneIcon />}
            label="Finish"
            loading={submitting}
            type="submit"
          />
        </FormContainer>
      )}
    />
  );
}

PasswordForm.propTypes = {
  onComplete: PropTypes.func.isRequired,
};

export default PasswordForm;
